<template>
  <v-dialog 
    :value="value" 
    @change="handleChange" 
    @click:outside="handleClose"
    width="450px"
    scrollable
  >
    <v-card flat>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ dialogTitle }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="height: 500px;">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-list>
              <v-row dense>
                <v-col cols="10" align-self="center">
                  <v-subheader class="px-0">
                    {{ $capitalize($tc('model.risk_location_contact_address')) }}
                  </v-subheader>
                </v-col>
                <v-col>
                  <v-col cols="2" align-self="center">
                    <v-btn icon color="primary" @click.prevent.stop="newAddress"><v-icon>mdi-plus</v-icon></v-btn>
                  </v-col>
                </v-col>
              </v-row>
              <v-list-item v-if="contact_addresses.length === 0">
                <v-list-item-avatar color="grey lighten-2">
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ $capitalize($tc('model.risk_location_no_contact_address')) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item-group v-model="parent_id">
                <v-list-item
                  v-for="address in contact_addresses"
                  :key="address.id"
                  :value="address.id"
                  v-slot="{ active }"
                >
                  <v-list-item-action>
                    <v-icon color="primary">
                      {{  active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ street(address) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ __('Distrito', 'risk_location') }}:    
                      {{ country_area(address) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ __('Concelho', 'risk_location') }}:     
                      {{ city(address) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ __('Freguesia', 'risk_location') }}:     
                      {{ city_area(address) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click.prevent.stop="editAddress(address)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-tab-item>
          <v-tab-item eager>
            <v-subheader class="px-0">
              <v-icon small left @click.prevent.stop="goBackToAddresses">mdi-arrow-left</v-icon>
              <a class="link" @click.prevent.stop="goBackToAddresses">
                Voltar
              </a>
            </v-subheader>
            <risk-location-address-form
              ref="riskLocationForm"
              v-model="address_form"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions v-if="tab === 0">
        <v-spacer />
        <v-btn @click="handleClose" text>
          {{ __('Cancelar', 'leads') }}
        </v-btn>
        <v-btn @click="confirmAddress" color="primary" :disabled="contact_addresses.length === 0">
          {{ $capitalize($tc('model.risk_location_confirm_button'))}}
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer />
        <v-btn @click="handleClose" text>
          {{ __('Cancelar', 'leads') }}
        </v-btn> 
        <v-btn 
          @click="saveAddress" 
          color="primary"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { saveAddress } from '@/services/contact';
import RiskLocationAddressForm from '@/components/Lead/RiskLocationAddressForm';
import i18n from '@/mixins/i18n';

export default {
  name: "select-risk-location-dialog",
  mixins: [i18n],
  components: {
    RiskLocationAddressForm
  },
  props: {
    value: {
      type: Boolean
    },
    contact: {
      type: Object
    },
    risk_location: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: {},
      parent_id: null,
      tab: 0,
      contact_addresses: [],
      address_form: {}
    };
  },
  computed: {
    dialogTitle() {
      if (this.tab === 0) {
        return this.$capitalize(this.$tc('model.risk_location_select_risk_location_title'));
      }

      return this.address_form.id ? 'Editar endereço' : 'Novo endereço';
    }
  },
  methods: {
    editAddress(address) {
      this.tab = 1;
      this.$refs.riskLocationForm.fillForm(address);
    },
    newAddress() {
      this.tab = 1;
      this.$refs.riskLocationForm.resetForm();
    },
    async saveAddress() {
      const validation = await this.$refs.riskLocationForm.validateForm();
      if (!validation) {
        return;
      }

      this.saving_address = true;
      try {
        const response = await saveAddress(this.contact?.id, {
          ...this.address_form,
          country_area_id: this.address_form?.country_area?.id,
          city_id: this.address_form?.city?.id,
          city_area_id: this.address_form?.city_area?.id
        });
        this.contact_addresses = response.contact?.addresses || [];
        this.parent_id = response.address?.id;
        this.localValue = {
          ...response.address,
          id: this.localValue?.id,
          parent_id: response.address?.id
        };
        this.tab = 0;
      } catch(error) {
        this.$store.commit("sendMessage", {
            text: 'Não foi possível salvar o endereço do contato',
            color: "red",
        });
      } finally {
        this.saving_address = false;
      }
    },
    goBackToAddresses() {
      this.tab = 0;
    },
    setInitialValue() {
      this.localValue = this.risk_location;
      this.parent_id = this.risk_location?.parent_id;
      this.contact_addresses = this.contact?.addresses || [];
    },
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
    },
    confirmAddress() {
      const address = this.contact_addresses.find(rec => rec.id == this.parent_id);
      this.localValue = {
        ...address,
        parent_id: this.parent_id
      };
      this.$listeners.changeRiskLocation(this.localValue);
      this.handleClose();
    },
    street(address) {
      if (address?.street) {
        const number = address.number ? ', ' + address.number :  '';
        return address.street + number;
      }

      return "N/A";
    },
    country_area(address) {
      return address?.country_area?.name || "N/A";
    },
    city(address) {
      return address?.city?.name || "N/A";
    },
    city_area(address) {
      return address?.city_area?.name || "N/A";
    }
  },
  watch: {
    risk_location() {
      this.setInitialValue();
    },
    value(value) {
      if (value)
        this.setInitialValue();
    }
  },
  created() {
    this.setInitialValue();
  }
}
</script>

<style>

</style>