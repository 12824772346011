<template>
  <div class="lead_resume">
    <v-card outlined :loading="refreshingHistory">
      <v-card-title>
        {{ __("Histórico", "leads") }}
        <v-spacer />
        <v-btn
          icon
          @click="refreshLeadStatusHistory"
          :disabled="refreshingHistory"
          ><v-icon>mdi-refresh</v-icon></v-btn
        >
      </v-card-title>
      <v-timeline dense>
        <v-timeline-item
          v-for="(status, index) in status_history"
          :key="status.id"
          :color="getStatusColor(index)"
          fill-dot
        >
          <v-row class="pt-1">
            <v-col>
              <strong>{{ status.status.name }}</strong>
              <div class="caption">
                {{ isLastItem(index) ? "" : __("durante", "leads") + " "
                }}<b>{{ getStatusDuration(index) }}</b>
                <br />
                {{ getUserName(status) }}
                {{ __("em", "leads") }}
                {{ formatDateTime(status.created_at) }}

                <br />
              </div>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatDateTime } from "@/tools/date";
import { getStatusHistory } from "@/services/lead";
import i18n from "@/mixins/i18n";

export default {
  name: "lead-resume",
  mixins: [i18n],
  data: () => ({
    refreshingHistory: false,
  }),
  methods: {
    getUserName(status) {
      if (!status.user) {
        return null;
      }

      return status.user.name;
    },
    isLastItem(index) {
      return index == this.lead.status_history.length - 1;
    },
    getStatusDuration(index) {
      const actual_status = this.$moment(
        this.lead.status_history[index].created_at
      );

      const last_status = this.isLastItem(index);
      if (last_status) {
        return this.$moment
          .duration(actual_status.diff(this.$moment()))
          .humanize(last_status);
      } else {
        const next_status = this.$moment(
          this.lead.status_history[index + 1].created_at
        );

        return this.$moment
          .duration(next_status.diff(actual_status))
          .humanize(last_status);
      }
    },
    getStatusColor(index) {
      const last_status = index == this.lead.status_history.length - 1;
      const actual_status_color = this.lead.status_history[index].status.color;

      return last_status ? actual_status_color : "#ccc";
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    updateStatusHistory(status_history) {
      this.$store.commit("setLeadStatusHistory", status_history);
    },
    async refreshLeadStatusHistory() {
      this.refreshingHistory = true;
      this.updateStatusHistory([]);

      try {
        const response = await getStatusHistory(this.lead.id);

        this.updateStatusHistory(response.status_history);
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: this.__(
            "Não foi possível atualizar o histórico da lead",
            "leads"
          ),
          color: "red",
        });
      } finally {
        this.refreshingHistory = false;
      }
    },
  },
  computed: {
    ...mapState(["lead"]),
    status_history: function () {
      return this.$store.getters.getLeadStatusHistory;
    },
  },
};
</script>
