<template>
  <div>
    <v-card outlined persistent>
      <v-list two-line>
        <v-list-item>
          <v-list-item-avatar v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-list-item-avatar>
          <v-list-item-icon v-else>
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="title text-wrap"
              :class="{
                'red--text': !lead.unity.user.is_active,
              }"
              v-if="lead.unity"
            >
              {{ lead.unity.user.name }}
              <span v-if="!lead.unity.user.is_active">( Bloqueado )</span>
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ __("Nenhuma unidade atribuída", "schedulings") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ __("Unidade Responsável", "schedulings") }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-menu offset-x left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <ScopeProvider scope="leads.read_unities_historic">
                  <v-list-item @click="handleOpenUnityHistory()" link>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-clock</v-icon> Histórico de unidades
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>

                <v-divider></v-divider>

                <ScopeProvider scope="leads.update_unity_automatically">
                  <v-list-item @click="handleJustifyAutomaticAssign()" link>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-auto-fix</v-icon>
                        {{ automaticAssignText }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>

                <ScopeProvider scope="leads.update_unity_manually">
                  <v-list-item @click="openUnityDialog()" link>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon left>mdi-magnify-scan</v-icon> Atribuir unidade
                        manualmente
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>

                <v-divider></v-divider>

                <ScopeProvider scope="leads.update_unity_manually">
                  <v-list-item
                    @click="handleJustifyUnassignUnity()"
                    link
                    :class="{ 'red--text': lead.unity_id }"
                    :disabled="!lead.unity_id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :disabled="!lead.unity_id" left color="red">
                          mdi-cancel
                        </v-icon>
                        Remover unidade
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </ScopeProvider>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <ChangeLeadUnityDialog v-model="unity_dialog" />
    <TextFieldDialog
      title="Mudar unidade"
      subtitle="A plataforma substituirá a unidade atual automaticamente"
      field-label="Motivo"
      field-placeholder="Justifique a troca de unidade"
      confirm-text="Confirmar"
      ref="justificationDialog"
      @submit="handleAutomaticAssign($event.text)"
    ></TextFieldDialog>

    <TextFieldDialog
      title="Remover unidade"
      subtitle="O serviço ficará sem unidade responsável"
      field-label="Motivo"
      field-placeholder="Justifique a remoção"
      confirm-text="Confirmar"
      ref="removeJustificationDialog"
      @submit="handleUnassignUnity($event.text)"
    ></TextFieldDialog>

    <LeadUnityHistoryDialog
      v-model="unityHistoryDialog"
      :items="unityHistory"
    />
  </div>
</template>

<script>
import ChangeLeadUnityDialog from "@/components/Lead/ChangeLeadUnityDialog";
import { sugestAndAssign, unassign, getHistory } from "@/services/lead_unities";
import TextFieldDialog from "../TextFieldDialog.vue";
import LeadUnityHistoryDialog from "@/components/LeadUnities/LeadUnityHistoryDialog";
import i18n from '@/mixins/i18n';
import ScopeProvider from "@/components/ScopeProvider";

export default {
  mixins: [i18n],
  name: "resume-unity-card",
  mixins: [i18n],
  components: {
    ChangeLeadUnityDialog,
    TextFieldDialog,
    LeadUnityHistoryDialog,
    ScopeProvider,
  },
  data() {
    return {
      unity_dialog: false,
      unityHistory: [],
      unityHistoryDialog: false,
      loading: false,
    };
  },
  methods: {
    openUnityDialog() {
      this.unity_dialog = true;
    },

    handleJustifyAutomaticAssign() {
      if (this.lead.unity_id) {
        this.$refs.justificationDialog.init();
        return;
      }

      this.handleAutomaticAssign("");
    },

    handleJustifyUnassignUnity() {
      this.$refs.removeJustificationDialog.init();
    },

    async handleAutomaticAssign(motive) {
      this.loading = true;
      try {
        const response = await sugestAndAssign(this.lead.id, {
          motive,
        });

        if (response.assigned_unity === null) {
          this.$store.commit(
            "alert",
            "Não encontramos unidades disponíveis, atribua manualmente"
          );
          this.$store.commit("unassignUnity");
        } else {
          this.$store.commit("assignUnity", response.assigned_unity);

          this.$store.commit("alert", "Unidade atribuída automaticamente");
        }
      } catch (error) {
        if (error.response?.data?.message) {
          this.$store.commit("error", error.response.data.message);
          return;
        }

        this.$store.commit("error", "Falha ao atribuir unidade");
      } finally {
        this.loading = false;
      }
    },

    async handleUnassignUnity(motive) {
      await unassign(this.lead.id, {
        motive,
      });

      this.$store.commit("unassignUnity");
    },

    async handleOpenUnityHistory() {
      this.unityHistoryDialog = true;

      const response = await getHistory(this.lead.id);
      this.unityHistory = response.data;
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    automaticAssignText() {
      if (!this.lead.unity_id) {
        return "Sugerir unidade";
      } else {
        return "Sugerir outra unidade";
      }
    },
  },
};
</script>
