<template>
  <v-dialog v-model="dialog" persistent width="450">
    <v-form @submit.prevent="handleSubmit()">
      <v-card>
        <v-card-title v-text="title" />
        <v-card-subtitle v-text="subtitle" v-if="subtitle" />

        <v-card-text>
          <v-text-field
            v-model="form.text"
            :label="fieldLabel"
            outlined
            :placeholder="fieldPlaceholder"
            counter="255"
            autofocus
          ></v-text-field>

          <div class="actions mt-5 text-right">
            <v-btn @click="handleCancel()" text color="primary">
              {{ cancelText }}
            </v-btn>
            <v-btn type="submit" color="primary" class="mt-1">
              {{ confirmText }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    fieldLabel: String,
    fieldPlaceholder: String,
    confirmText: {
      type: String,
      default: "Confirmar",
    },
    cancelText: {
      type: String,
      default: "Cancelar",
    },
  },

  data() {
    return {
      dialog: false,
      form: {
        text: "",
      },
    };
  },

  methods: {
    init(text = null) {
      if (!text) {
        this.clear();
      } else {
        this.form.text = text;
      }
      this.openDialog();
    },
    openDialog() {
      this.dialog = true;

      this.$emit("dialog:open");
    },
    closeDialog() {
      this.dialog = false;

      this.$emit("dialog:close");
    },
    clear() {
      this.form = {
        text: "",
      };
    },
    handleSubmit() {
      this.$emit("submit", this.form);
      this.closeDialog();
    },
    handleCancel() {
      this.closeDialog();
      this.$emit("cancel");
    },
  },
};
</script>
