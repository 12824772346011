<template>
  <div class="form-add-participant">
    <v-form autocomplete="off" @submit.prevent="handleSubmitForm">
      <v-card>
        <v-card-title>
          {{
            id
              ? form.contact.name
              : $capitalize($tc("model.insureds_new_insured"))
          }}
          <v-spacer></v-spacer>
          <v-btn @click="handleCloseForm" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <ValidationProvider
              name="contact_id"
              :rules="{
                required: !!!id
              }"
              v-slot="{ errors }"
            >
              <select-contact
                v-model="contact"
                item-text="name"
                :label="
                  $capitalize($tc('model.insureds_contact_of_insured'))
                "
                :placeholder="
                  $capitalize($tc('model.insureds_search_contacts'))
                "
                :error-messages="errors"
                :disabled="!!id || checkInsured()"
              />
            </ValidationProvider>

            <ValidationProvider
              name="type_id"
              rules="required"
              v-slot="{ errors }"
              v-if="!checkInsured()"
            >
              <ParticipantsTypePicker
                v-model="type_id"
                name="type_id"
                :error-messages="errors"
                :disabled="checkInsured()"
                class="mt-4"
              />
            </ValidationProvider>
            <v-text-field
              v-else
              class="mt-4"
              :value="form.type.description"
              :label="$capitalize($tc('model.insureds_type_of_insured'))"
              disabled
            />

            <v-switch
              v-model="insured"
              name="insured"
              :label="$capitalize($tc('model.insureds_participant_injured_switch'))"
              :disabled="checkInsured()"
            />

            <ValidationProvider
              name="risk_location"
              :rules="insured ? 'required' : ''"
              v-slot="{ errors }"
            >
              <select-risk-location
                v-model="risk_location"
                class="my-4"
                :contact="contact"
                item-text="street"
                :label="$capitalize($tc('model.leads_risk_location_lead'))"
                :error-messages="errors"
              />
            </ValidationProvider>

          </ValidationObserver>
          <v-alert
            color="blue lighten-3"
            icon="mdi-information"
            v-if="checkInsured()"
            >{{
              $capitalize($tc("model.insureds_restrictions_has_change"))
            }}</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleCloseForm" text>{{
            $capitalize($tc("model.insureds_cancel_button"))
          }}</v-btn>
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
            >{{ $capitalize($tc("model.insureds_save_button")) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import SelectContact from "@/components/SelectContact";
import SelectRiskLocation from "@/components/Lead/Participant/SelectRiskLocation";
import ParticipantsTypePicker from "@/components/participants/ParticipantsTypePicker";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  components: { SelectRiskLocation, SelectContact, ParticipantsTypePicker },
  data: () => ({
    contact: null,
    type_id: null,
    loading: false,
    insured: true,
    risk_location: null,
    id: null
  }),
  props: ["form"],
  model: {
    prop: "form",
    event: "change"
  },
  methods: {
    resetForm() {
      this.contact = null;
      this.risk_location = null;
      this.type_id = null;
      this.id = null;
      this.insured = true;

      this.$emit("change", {
        contact: null,
        risk_location: null,
        type_id: null,
        insured: true
      });
      this.$refs.form.reset();
    },
    handleCloseForm() {
      this.resetForm();
      this.$emit("close");
    },
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();
    
      if (validation) {
        const payload = {
          contact_id: this.contact?.id,
          lead_id: this.lead.id,
          type_id: this.type_id,
          insured: this.insured,
          risk_location: this.risk_location
        };
        try {
          this.loading = true;
          const response = this.id
            ? await this.updateParticipant(payload, this.id)
            : await this.storeParticipant(payload);
          this.$store.commit("sendMessage", {
            text: this.__('Registro adicionado com sucesso!', 'participants'),
            color: "success"
          });
          this.$emit("change", {
            ...response.data
          });
          this.$emit(this.id ? "update" : "create", response.data);
          this.handleCloseForm();
        } catch (error) {
          if (error.response) {
            switch (error.response.status) {
              case 422:
                this.$refs.form.setErrors(error.response.data.errors);
                break;

              case 400:
                this.$store.commit("sendMessage", {
                  text: error.response.data.message,
                  color: "red"
                });
                break;

              default:
                this.$store.commit("sendMessage", {
                  text: this.__('Algo deu errado, tente novamente mais tarde', 'participants'),
                  color: "red"
                });
                break;
            }
          } else {
            this.$store.commit("sendMessage", {
              text: error.response.data.message,
              color: "red"
            });
          }
        } finally {
          this.loading = false;
        }
      }
    },
    storeParticipant(payload) {
      return this.$http.post("/participants", payload);
    },
    updateParticipant(payload, id) {
      return this.$http.put("/participants/" + id, payload);
    },
    checkInsured() {
      if (this.contact?.id) {
        return this.contact?.id === this.$store.getters.getLead.insured_id;
      }

      return false;
    },
  },
  watch: {
    form: function(value) {
      if (value) {
        this.type_id = value.type_id;
        this.contact = value.contact;
        this.risk_location = value.risk_location;
        this.insured = value.insured;
        this.id = value.id;
      } else if (value == null) {
        this.resetForm();
      }
    },
  },
  computed: {
    lead: function() {
      return this.$store.getters.getLead;
    }
  },
  created() {
    if (this.form) {
      this.type_id = this.form.type_id;
      this.contact = this.form.contact;
      this.id = this.form.id;
      this.insured = this.form.insured;
      this.risk_location = this.form.risk_location;
    }
  }
};
</script>
