<template>
  <div>
    <v-subheader style="height: 30px;" class="py-0 px-0">
      {{ __('Local da vistoria', 'participants') }}
    </v-subheader>
    <v-card outlined>
      <v-list class="py-0">
        <v-list-item>
          <v-list-item-avatar color="grey lighten-2">
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="value">
            <v-list-item-title>{{ street }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ __('Distrito', 'risk_location') }}:
              {{ country_area }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              >{{ __('Concelho', 'risk_location') }}:
              {{ city }}
            </v-list-item-subtitle>
            <v-list-item-subtitle
              >{{ __('Freguesia', 'risk_location') }}:
              {{ city_area }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-if="!value">
            <v-list-item-title
              >{{ $capitalize($tc('model.risk_location_title_not_address_selected')) }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-if="errorMessages && errorMessages.length > 0"
              class="red--text pt-0 pb-2"
              >{{ errorMessages[0] }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="dialog = true" :disabled="$attrs.disabled">
              <v-icon v-if="value">mdi-pencil</v-icon>
              <v-icon v-if="!value">mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <!-- <v-card-text v-if="errorMessages && errorMessages.length > 0" class="red--text pt-0 pb-2">{{ errorMessages[0] }}</v-card-text> -->
      <select-risk-location-dialog
        v-if="dialog"
        v-model="dialog"
        :contact="contact"
        :risk_location="value"
        @changeRiskLocation="changeRiskLocation"
      />
    </v-card>
  </div>
</template>

<script>
import SelectRiskLocationDialog from "@/components/Lead/Participant/SelectRiskLocationDialog";
import i18n from '@/mixins/i18n';

export default {
  mixins: [i18n],
  name: "select-risk-location",
  components: { SelectRiskLocationDialog },
  props: {
    value: {
      type: Object
    },
    contact: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    changeRiskLocation(address) {
      this.$emit("input", address);
    }
  },
  computed: {
    street() {
      if (this.value?.street) {
        const number = this.value.number ? ", " + this.value.number : "";
        return this.value.street + number;
      }

      return "N/A";
    },
    country_area() {
      return this.value?.country_area?.name || "N/A";
    },
    city() {
      return this.value?.city?.name || "N/A";
    },
    city_area() {
      return this.value?.city_area?.name || "N/A";
    }
  }
};
</script>

<style></style>
