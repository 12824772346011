<template>
  <v-dialog :value="dialog" @input="handleInputDialog" width="650">
    <v-card>
      <v-card-title>
        Histórico de unidades
        <v-spacer></v-spacer>
        <v-btn @click="handleCloseDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle> Movimentação de unidades do processo </v-card-subtitle>
      <v-divider></v-divider>
      <div style="height: 70vh; overflow-y: auto; overflow-wrap: normal">
        <v-timeline v-if="items.length" dense clipped>
          <v-timeline-item
            v-for="(item, index) in items"
            :key="item.id"
            color="grey darken-2"
            fill-dot
            class="white--text"
          >
            <template v-slot:icon>
              <span class="white--text">{{ getItemCounter(index) }}.</span>
            </template>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle class="caption">
                  <span title="Data e hora da ação">
                    {{ item.created_at }}
                  </span>
                  •
                  <span title="Responsável"> {{ getAuthor(item) }} </span>
                </v-list-item-subtitle>
                <v-list-item-title class="text-wrap">
                  <span v-html="getMainMessage(item)"></span>
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.motive"
                  title="Justificativa do responsável"
                >
                  <v-icon size="13" color="grey" left> mdi-comment </v-icon>
                  {{ item.motive }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    items: Array,
  },

  model: {
    prop: "dialog",
  },

  methods: {
    getItemCounter(index) {
      return this.items.length - index;
    },
    getAuthor(item) {
      if (item.user) {
        return item.user.name;
      }

      return "Sistema";
    },
    getMainMessage(item) {
      if (item.unity === null) {
        return "Unidade removida";
      }

      return `<b>${
        item.unity.name
      }</b> atribuído ao processo ${this.getAssigningMethod(item)}`;
    },
    getAssigningMethod(item) {
      return item.automatic
        ? "por escolha da plataforma"
        : "por escolha do utilizador";
    },
    handleInputDialog(event) {
      this.$emit("input", event);
    },
    handleCloseDialog() {
      this.$emit("input", false);
    },
  },
};
</script>
